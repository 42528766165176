import { FC } from 'react'
import Link from 'next/link'
import { IQuestionBase, QuestionTypeBase } from '@/types'
import styles from './index.module.scss'
import { QuestionListItemLowerPart } from '../QuestionListItemLowerPart'

type Props = {
  question: IQuestionBase
  connected: boolean
}

export const QuestionListItem: FC<Props> = ({ question, connected }) => {
  return (
    <div className={styles.container}>
      <div className={styles.upperGroup}>
        <Link href={`/forum/${question.slug}`}>
          <a>
            <div className={styles.categoryGroup}>
              {question.questionType === QuestionTypeBase.POLL && <div className={styles.type}>Poll</div>}
              <div className={styles.category}>#{question?.questionCategory.name}</div>
            </div>
            <p className={styles.title}>{question?.title}</p>
          </a>
        </Link>
      </div>
      <QuestionListItemLowerPart question={question} connected={connected} />
    </div>
  )
}
