import { FC } from 'react'
import { IQuestionBase } from '@/types'
import { NoContent } from '@components/atoms/NoContent'
import { QuestionListItem } from '@components/molecules/QuestionListItem'
import { useWallet } from '@solana/wallet-adapter-react'
import { AffiliateLinkItem } from '@/components/molecules/AffiliateLinkItem'
import styles from './index.module.scss'

type Props = {
  items: IQuestionBase[]
}

export const QuestionList: FC<Props> = ({ items = [] }) => {
  const { connected } = useWallet()

  if (items.length === 0) {
    return <NoContent>No posts are published yet.</NoContent>
  }

  return (
    <div className={styles.container}>
      {items.map((item, index) => {
        if ((index + 1) % 10 === 0) {
          return (
            <div key={item.id}>
              <div className={styles.itemWithPromotion}>
                <QuestionListItem question={item} connected={connected} />
              </div>
              <div className={styles.promotion}>
                <AffiliateLinkItem type='female' />
              </div>
            </div>
          )
        }
        if ((index + 1) % 5 === 0) {
          return (
            <div key={item.id}>
              <div className={styles.itemWithPromotion}>
                <QuestionListItem question={item} connected={connected} />
              </div>
              <div className={styles.promotion}>
                <AffiliateLinkItem type='male' />
              </div>
            </div>
          )
        }
        return (
          <div key={item.id} className={styles.item}>
            <QuestionListItem question={item} connected={connected} />
          </div>
        )
      })}
    </div>
  )
}
