import { FC } from 'react'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

type Props = {
  type?: 'male' | 'female'
}

export const AffiliateLinkItem: FC<Props> = ({ type }) => {
  return (
    <div className={styles.container}>
      <a href={sanitizeUrl(process.env.NEXT_PUBLIC_BYBIT_AFFILIATE_URL)} target='_blank' rel='noreferrer'>
        {type === 'male' && (
          <img src='/images/common/bybit_affiliate_male.png' alt='Bybit affiliate' className={styles.image} />
        )}
        {type === 'female' && (
          <img src='/images/common/bybit_affiliate_female.png' alt='Bybit affiliate' className={styles.image} />
        )}
        <div className={styles.textGroup}>
          <img src='/images/common/service_link_icon.svg' alt='External Link' className={styles.icon} />
          <span className={styles.text}>Promoted</span>
        </div>
      </a>
    </div>
  )
}
