import { FC } from 'react'
import Link from 'next/link'
import { FooterSnsLink } from '@/components/molecules/FooterSnsLink'
import { FooterSubMenu } from '@/components/molecules/FooterSubMenu'
import { ShowButtonForMobile } from '@/components/molecules/ShowButtonForMobile'
import styles from './index.module.scss'

type MenuItem = {
  name: string
  link: string
}

const menuItems: MenuItem[] = [
  {
    name: 'Listing/ Fundraising',
    link: '/categories/listing-fundraising',
  },
  {
    name: 'Investment/ Airdrop',
    link: '/categories/investment-airdrop',
  },
  {
    name: 'Token Economy/DeFi',
    link: '/categories/token-economy-de-fi',
  },
  {
    name: 'KOL/Media',
    link: '/categories/kol-media',
  },
  {
    name: 'Engineering',
    link: '/categories/engineering',
  },
  {
    name: 'Design',
    link: '/categories/design',
  },
  {
    name: 'Tax/Accounting',
    link: '/categories/tax-accounting',
  },
  {
    name: 'Legal',
    link: '/categories/legal',
  },
  {
    name: 'News',
    link: '/categories/news',
  },
]

export const ShowMoreOnTopForMb: FC = () => {
  return (
    <ShowButtonForMobile>
      <div className={styles.categories}>
        <p className={styles.title}>Categories</p>
        {menuItems.map((item) => (
          <div key={item.name} className={styles.item}>
            <Link href={item.link}>
              <a className={styles.link}>{item.name}</a>
            </Link>
          </div>
        ))}
      </div>
      <FooterSubMenu />
      <FooterSnsLink />
    </ShowButtonForMobile>
  )
}
