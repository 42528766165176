import { FC } from 'react'
import TwitterSvg from '@/public/images/common/twitter_footer.svg'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

type LinkItem = {
  link: string
  icon: React.SVGProps<SVGElement>
}

const linkItems: LinkItem[] = [
  {
    link: 'https://twitter.com/AQA314',
    icon: <TwitterSvg />,
  },
]

export const FooterSnsLink: FC = () => {
  return (
    <div className={styles.container}>
      {linkItems.map((item) => {
        return (
          <a key={item.link} className={styles.item} href={sanitizeUrl(item.link)} target='_blank' rel='noreferrer'>
            <div className={styles.icon}>{item.icon}</div>
          </a>
        )
      })}
    </div>
  )
}
