import { changeDecimalToString } from '@/utils'
import { FC } from 'react'
import styles from './index.module.scss'

type Props = {
  amountOfThanks: number
}

export const TokenThanksItem: FC<Props> = ({ amountOfThanks }) => {
  return (
    <>
      {amountOfThanks !== 0 && (
        <div className={styles.container}>
          <div className={styles.icon}>
            <img src='/images/common/aqa_token_blue.png' alt='aqa_token_blue' className='img-fluid' />
          </div>
          <div className={styles.text}>
            <span className={styles.bold}>{changeDecimalToString(amountOfThanks)}AQA</span>&nbsp;Thanks
          </div>
        </div>
      )}
    </>
  )
}
