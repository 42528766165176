import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { TransferTokenButton } from '@components/organisms/TransferTokenButton'
import { IQuestionBase, TokenTransactionCategoryBase } from '@/types'
import { useStores } from '@/utils'
import { TokenThanksItem } from '@components/molecules/TokenThanksItem'
import styles from './index.module.scss'

type Props = {
  question: IQuestionBase
  amountOfThanks?: number
  hasSmallBtn?: boolean
}

export const QuestionTipButtonGroup: FC<Props> = observer(({ question, amountOfThanks = 0, hasSmallBtn }) => {
  const { viewer } = useStores()
  const [thanks, setThanks] = useState(amountOfThanks)

  const addTransaction = async (amount: string, signature: string): Promise<boolean> => {
    const output = await viewer.viewer?.addTokenTransaction({
      tokenTransaction: {
        amount,
        category: TokenTransactionCategoryBase.QUESTION,
        recieverId: question?.questioner?.id,
        signature,
      },
      questionId: question?.id,
    })

    if (output.isSuccessful) {
      setThanks(thanks + Number(output.data.tokenTransaction.amount))
      return true
    }
    return false
  }

  return (
    <div className={styles.container}>
      <div className={hasSmallBtn ? styles.smallBtn : styles.btn}>
        <TransferTokenButton user={question?.questioner} addTransaction={addTransaction} />
      </div>
      <div className={styles.thanks}>
        <TokenThanksItem amountOfThanks={thanks} />
      </div>
    </div>
  )
})
