import { FC, PropsWithChildren, ReactElement } from 'react'
import styles from './index.module.scss'

type Props = {
  aside: ReactElement
  header?: ReactElement
}

export const ForumTwoColumnContentTemplate: FC<PropsWithChildren<Props>> = ({ children, aside, header }) => (
  <div className={styles.container}>
    <div className={styles.main}>
      {header && <div className={styles.header}>{header}</div>}
      {children}
    </div>
    <div className={styles.aside}>{aside}</div>
  </div>
)
