import { Avatar } from '@/components/atoms/Avatar'
import { getUserAvatar, useStores } from '@/utils'
import { observer } from 'mobx-react'
import { FC } from 'react'
import PostSvg from '@/public/images/common/edit_note_icon.svg'
import styles from './index.module.scss'

export const ForumFormButton: FC = observer(() => {
  const { ui, viewer } = useStores()

  const toggleModal = () => {
    ui.toggleForumModal()
  }

  if (ui.isMobile) {
    return (
      <div className={styles.containerForMobile}>
        <button type='button' onClick={toggleModal} className={styles.buttonForMobile}>
          <PostSvg />
        </button>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar src={getUserAvatar(viewer?.viewer)} alt={viewer?.viewer.name} />
      </div>
      <button type='button' onClick={toggleModal} className={styles.button}>
        <PostSvg />
        <div className={styles.text}>Start a post</div>
      </button>
    </div>
  )
})
