import { getSignInPath } from '@/utils'
import { useRouter } from 'next/router'
import { FC } from 'react'
import styles from './index.module.scss'

export const LoginToAnswerButton: FC = () => {
  const router = useRouter()

  const onClick = () => {
    router.push(getSignInPath(router.asPath))
  }

  return (
    <div className={styles.container}>
      <button type='button' onClick={onClick} className={styles.btn}>
        <div className={styles.text}>Log in to Answer</div>
      </button>
    </div>
  )
}
