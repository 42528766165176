import { FC } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { OrderDirection, QuestionOrderBase, QuestionOrderFieldBase } from '@/types'
import styles from './index.module.scss'

type Props = {
  search: (keyword: string, orderBy: QuestionOrderBase) => Promise<void>
  searchKeyword: string
  setOrderBy: (orderBy: QuestionOrderBase) => void
}

type Value = {
  keyword: string
  orderBy: QuestionOrderBase
}

export const SortGroupForQuestions: FC<Props> = observer(({ search, searchKeyword, setOrderBy }) => {
  const initialValue: Value = {
    keyword: searchKeyword,
    orderBy: {
      direction: OrderDirection.DESC,
      questionOrderField: QuestionOrderFieldBase.ANSWERS_COUNT,
    },
  }

  const validationSchema = Yup.object().shape({})

  const setSearch = async (values: Value) => {
    await search(values.keyword, values.orderBy)
  }

  return (
    <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={() => {}}>
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <div className={styles.container}>
              <label
                className={
                  values.orderBy.questionOrderField === QuestionOrderFieldBase.ANSWERS_COUNT
                    ? styles.active
                    : styles.radio
                }
              >
                <Field
                  type='radio'
                  name='orderBy.questionOrderField'
                  value={QuestionOrderFieldBase.ANSWERS_COUNT}
                  onChange={(e: { target: { value: QuestionOrderFieldBase } }) => {
                    setFieldValue('orderBy.questionOrderField', e.target.value)
                    values.orderBy.questionOrderField = e.target.value
                    setOrderBy(values.orderBy)
                    values.keyword = searchKeyword
                    setSearch(values)
                  }}
                />
                Replies
              </label>
              <label
                className={
                  values.orderBy.questionOrderField === QuestionOrderFieldBase.CREATED_AT ? styles.active : styles.radio
                }
              >
                <Field
                  type='radio'
                  name='orderBy.questionOrderField'
                  value={QuestionOrderFieldBase.CREATED_AT}
                  onChange={(e: { target: { value: QuestionOrderFieldBase } }) => {
                    setFieldValue('orderBy.questionOrderField', e.target.value)
                    values.orderBy.questionOrderField = e.target.value
                    setOrderBy(values.orderBy)
                    values.keyword = searchKeyword
                    setSearch(values)
                  }}
                />
                Recent
              </label>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
})
