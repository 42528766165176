import classNames from 'classnames'
import Link from 'next/link'
import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { sanitizeUrl } from '../../../utils'
import styles from './index.module.scss'

type Props = {
  componentType?: 'button' | 'a'
  href?: string
  anchorTarget?: '_self' | '_blank'
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const IconButton: FC<PropsWithChildren<Props>> = ({
  children,
  componentType = 'button',
  href = '',
  anchorTarget = '_self',
  disabled = false,
  onClick,
}) => {
  const className = classNames(
    styles.container,
    {
      [styles.disabledButton]: disabled && componentType === 'button',
    },
    {
      [styles.disabledLink]: disabled && componentType === 'a',
    }
  )

  if (componentType === 'a') {
    return (
      <Link href={sanitizeUrl(href)}>
        <a className={className} target={anchorTarget}>
          {children}
        </a>
      </Link>
    )
  }

  return (
    <button type='button' className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}
