import { FC } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'
import { sanitizeUrl } from '../../../utils'

type MenuItem = {
  name: string
  link: string
  isExternal: boolean
}

// TODO: Item 調整
const menuItems: MenuItem[] = [
  {
    name: '個人情報保護方針',
    link: '/privacypolicy',
    isExternal: false,
  },
  {
    name: '利用規約',
    link: '/terms',
    isExternal: false,
  },
  {
    name: 'お問い合わせ',
    link: process.env.NEXT_PUBLIC_SUPPORT_FORM_URL,
    isExternal: true,
  },
  {
    name: 'AQA Foundation',
    link: process.env.NEXT_PUBLIC_AQA_FOUNDATION_URL,
    isExternal: true,
  },
]

export const FooterSubMenu: FC = () => {
  const { t } = useTranslation(['global'])

  return (
    <div className={styles.container}>
      {menuItems.map((item) => {
        if (item.isExternal) {
          return (
            <a key={item.link} className={styles.item} href={sanitizeUrl(item.link)} target='_blank' rel='noreferrer'>
              {t(`${item.name}`)}
            </a>
          )
        }
        return (
          <Link key={item.link} href={sanitizeUrl(item.link)}>
            <a className={styles.item}>{t(`${item.name}`)}</a>
          </Link>
        )
      })}
    </div>
  )
}
