import { FC } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { InputFieldGroup } from '@/components/molecules/InputFieldGroup'
import { QuestionOrderBase } from '@/types'

type Props = {
  search: (keyword: string, orderBy: QuestionOrderBase) => Promise<void>
  orderBy: QuestionOrderBase
  setSearchKeyword: (word: string) => void
}

type Value = {
  keyword: string
  orderBy: QuestionOrderBase
}

export const KeywordSearchGroupForQuestions: FC<Props> = observer(({ search, orderBy, setSearchKeyword }) => {
  const initialValue: Value = {
    keyword: '',
    orderBy,
  }

  const validationSchema = Yup.object().shape({})

  const setSearch = async (values: Value) => {
    await search(values.keyword, values.orderBy)
  }

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={(values: Value, actions) => {
        setSearchKeyword(values.keyword)
        setSearch({ keyword: values.keyword, orderBy })
        actions.setSubmitting(false)
      }}
    >
      {() => {
        return (
          <Form>
            <Field
              name='keyword'
              type='text'
              fieldType='searchRound'
              placeholder='Search Posts'
              component={InputFieldGroup}
            />
          </Form>
        )
      }}
    </Formik>
  )
})
